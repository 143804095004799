<template>
  <KpiStatBase
    :title="$t('general.reach_factor')"
    :ptd="ptd"
    :pct="pct"
    :target="target"
    :is-active="isActive"
    data-testid="reachFactor"
  >
    {{ value }}
  </KpiStatBase>
</template>

<script>
import KpiStatExtendedBase from './KpiStatExtendedBase'

export default {
  extends: KpiStatExtendedBase
}
</script>
