<template>
  <KpiStatBase
    :title="undefined"
    :ptd="ptd"
    :pct="pct"
    :target="target"
    :is-active="isActive"
    class="KpiStat--AClippings"
    data-testid="aplusClippings"
  >
    <div slot="title" class="is-flex is-aligned-middle">
      <tier tier="aplus" /> <span class="m-l-s">{{ $t('general.clippings') }}</span>
    </div>
    {{ formattedValue }}
  </KpiStatBase>
</template>

<script>
import { round } from '@hypefactors/shared/js/utils/numberUtils'
import KpiStatExtendedBase from './KpiStatExtendedBase'

export default {
  extends: KpiStatExtendedBase,

  computed: {
    formattedValue: function () {
      const precision = Math.abs(this.value) > 0.1 ? 0 : 1
      return round(this.value, precision) + '%'
    }
  }
}
</script>
