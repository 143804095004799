<template>
  <div v-if="markets.length" class="KpiCampaignMarkets">
    <button class="button is-fullwidth is-black is-outlined" @click="areMarketsVisible = !areMarketsVisible">
      {{ buttonText }}
    </button>
    <transition name="fade">
      <div v-if="areMarketsVisible" class="KpiCampaignMarkets__list p-a-s">
        <KpiCampaignMarketsMarket
          v-for="market in markets"
          :key="market.id"
          :market="market"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import KpiCampaignMarketsMarket from './KpiCampaignMarketsMarket'

export default {
  components: {
    KpiCampaignMarketsMarket
  },
  props: {
    markets: {
      type: Array,
      default: () => ([])
    },
    campaign: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      areMarketsVisible: false
    }
  },
  computed: {
    buttonText () {
      return this.areMarketsVisible ? this.$t('components.kpi_campaign_markets.hide_markets') : this.$t('components.kpi_campaign_markets.show_markets')
    }
  },
  created () {
    this.areMarketsVisible = Number(this.$route.query.kpiId) === this.campaign.id
  }
}
</script>

<style lang='scss'>
@import '~utils';

.KpiCampaignMarkets__list {
  background: lighten($grey-lighter, 4%);
}
</style>
