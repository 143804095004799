import KpiStatBase from './KpiStatBase'

export default {
  components: {
    KpiStatBase
  },
  props: {
    ptd: {
      type: Number,
      default: 0
    },
    pct: {
      type: Number,
      default: 0
    },
    target: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 0
    },
    isActive: {
      type: Boolean,
      default: true
    }
  }
}
