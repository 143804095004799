<template>
  <KpiStatBase
    :title="$t('general.clippings')"
    :ptd="ptd"
    :pct="pct"
    :target="target"
    :is-active="isActive"
    data-testid="clippings"
  >
    {{ abbr(value) }}
  </KpiStatBase>
</template>

<script>
import KpiStatExtendedBase from './KpiStatExtendedBase'
import AbbrMixin from '@hypefactors/shared/js/mixins/AbbrMixin'

export default {
  extends: KpiStatExtendedBase,

  mixins: [AbbrMixin]
}
</script>
