<template>
  <Square
    :title="!$slots.title ? title : undefined"
    class="KpiStatBase"
    size="large"
    :style="loadingBarStyles"
  >
    <template slot="title">
      <slot name="title" />
    </template>
    <div class="KpiStatBase__total has-text-muted">
      <template v-if="isActive">
        <div class="KpiStatBase__total-title">
          {{ $t('components.performance.kpi_edit.kpi_stat_base.current_total') }}
        </div>
        <div class="KpiStatBase__total-value has-text-weight-bold">
          <slot>{{ $t('general.not_available') }}</slot>
        </div>
      </template>
      <div v-else class="has-text-centered has-text-primary has-text-weight-semibold">
        {{ $t('components.performance.kpi_edit.kpi_stat_base.kpi_is_disabled') }}
      </div>
    </div>
    <div v-if="isActive" slot="footer" class="KpiStatBase__footer has-text-muted">
      <div class="KpiStatBase__MeaValue KpiStatBase__MeaValue--ptd">
        {{ $t('components.performance.kpi_edit.kpi_stat_base.ptd') }}
        <span
          :class="{'has-text-success':ptd >= 1 , 'has-text-danger': ptd < 1}"
          class="footer-value"
        >
          {{ ptd }}
        </span>
      </div>
      <div class="KpiStatBase__MeaValue KpiStatBase__MeaValue--pct">
        {{ $t('components.performance.kpi_edit.kpi_stat_base.pct_reached') }} <span>{{ percentageInt(pct) }}</span>
      </div>
      <div class="KpiStatBase__MeaValue KpiStatBase__MeaValue--total">
        {{ $t('components.performance.kpi_edit.kpi_stat_base.total_target') }}
        <span>
          <slot name="target">
            {{ abbr(target) }}
          </slot>
        </span>
      </div>
    </div>
  </Square>
</template>

<script>
import PercentageIntMixin from '@hypefactors/shared/js/mixins/PercentageIntMixin'
import AbbrMixin from '@hypefactors/shared/js/mixins/AbbrMixin'

export default {
  mixins: [PercentageIntMixin, AbbrMixin],

  props: {
    title: {
      type: String,
      default () {
        return this.$t('components.performance.kpi_edit.kpi_stat_base.kpi_stat_base')
      }
    },
    ptd: {
      type: [Number, String],
      default: ''
    },
    pct: {
      type: Number,
      default: 0
    },
    target: {
      type: Number,
      default: 0
    },
    isActive: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    loadingBarStyles () {
      if (this.pct < 100) {
        return { backgroundImage: `linear-gradient(to right, hsl(0, 0%, 87%) ${this.pct}%, transparent 0%)` }
      }
      return {
        backgroundColor: '#e6f0dc'
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.KpiStatBase {
  &__total {
    &-title {
      font-size: 0.8em;
    }

    &-value {
      color: $dark;
      font-size: 2em;
      line-height: 1;
    }
  }

  &__footer {
    font-size: 1rem;
    line-height: 1.1;
  }

  &__MeaValue {
    display: flex;
    justify-content: space-between;

    span {
      font-weight: bold;
    }
  }
}
</style>
