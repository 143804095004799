<template>
  <div :data-testid="`campaign_${campaign.id}`" class="KpiCampaign">
    <kpi-campaign-meta :campaign="campaign" class="m-b-s">
      <div
        v-if="!readOnly"
        class="KpiCampaign__edit is-aligned-end is-flex is-aligned-middle"
      >
        <restricted-button
          :loading="isLoading"
          permission="kpi.delete"
          action-class="button is-plain is-primary is-narrow is-large"
          data-testid="DeleteKpiCampaign"
          @click="deleteCampaign"
        >
          <span class="icon">
            <v-icon type="trash" />
          </span>
        </restricted-button>
        <restricted-button
          v-if="!campaign.is_completed"
          :to="{ name: 'performance.kpi.edit', params: { kpiId: campaign.id } }"
          permission="kpi.update"
          action-class="button is-plain is-primary is-narrow is-large"
          data-testid="EditKpiCampaign"
        >
          <span class="icon">
            <v-icon type="pencil" />
          </span>
        </restricted-button>
      </div>
    </kpi-campaign-meta>
    <KpiStatsRow
      :hf-value="hfValue"
      :roi="roi"
      :impressions="impressions"
      :reach-factor="reachFactor"
      :clippings="clippings"
      :aplus-clippings="aplusClippings"
      class="m-b-m KpiCampaign-Totals"
    />
    <KpiCampaignMarkets
      :markets="campaign.markets.data"
      :campaign="campaign"
    />
  </div>
</template>

<script>

import KpiStatsRow from '@/components/kpis/stats/KpiStatsRow'
import KpiCampaignMeta from '@/components/kpis/KpiCampaignMeta'
import KpiCampaignMarkets from '@/components/kpis/KpiCampaignMarkets'

export default {
  components: {
    KpiStatsRow,
    KpiCampaignMarkets,
    KpiCampaignMeta
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    hfValue () {
      return this.campaign.totals.pr_value
    },
    roi () {
      return this.campaign.totals.roi
    },
    impressions () {
      return this.campaign.totals.impressions
    },
    reachFactor () {
      return this.campaign.totals.penetration_factor
    },
    clippings () {
      return this.campaign.totals.amount_of_clippings
    },
    aplusClippings () {
      return this.campaign.totals.aplus_clippings
    }
  },
  methods: {
    deleteCampaign () {
      this.$confirm(this.$t('components.kpi_campaign.confirm_delete_campaign'))
        .then(() => {
          this.isLoading = true
          this.$api.delete(`kpi-campaigns/${this.campaign.id}`)
            .then(response => {
              this.isLoading = false
              this.$emit('delete:campaign', this.campaign.id)
            })
            .catch(error => {
              this.isLoading = false
              this.$displayRequestError(error, this.$t('errors.cannot_delete_kpi_campaign'))
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.KpiCampaign {
  margin-bottom: $spacing-xxlarge;

  &__name {
    font-size: $size-3;
    line-height: 1;
    font-weight: bold;
  }

  &__marketsCount {
    line-height: 1;
  }
}
</style>
