<template>
  <KpiStatBase
    :title="$t('general.hf_value')"
    :ptd="ptd"
    :pct="pct"
    :target="convertCurrency(target)"
    :is-active="isActive"
    data-testid="hfValue"
  >
    <span v-html="$moneyAbbrFormatHtml(value)" />
  </KpiStatBase>
</template>

<script>
import KpiStatExtendedBase from './KpiStatExtendedBase'
import { mapGetters } from 'vuex'

export default {
  extends: KpiStatExtendedBase,
  computed: {
    ...mapGetters(['convertCurrency'])
  }
}
</script>
