<template>
  <el-tooltip placement="top">
    <div slot="content">
      {{ $t('general.investment') }}: {{ abbrMoney(investments) }}
    </div>
    <KpiStatBase
      :title="$t('general.roi')"
      :ptd="ptd"
      :pct="pct"
      :is-active="isActive"
      data-testid="roi"
    >
      {{ percentageInt(value) }}

      <template slot="target">
        {{ percentageInt(target) }}
      </template>
    </KpiStatBase>
  </el-tooltip>
</template>

<script>
import KpiStatExtendedBase from './KpiStatExtendedBase'
import FormatMoneyAbbrMixin from '@hypefactors/shared/js/mixins/FormatMoneyAbbrMixin'
import PercentageIntMixin from '@hypefactors/shared/js/mixins/PercentageIntMixin'

export default {
  extends: KpiStatExtendedBase,

  mixins: [FormatMoneyAbbrMixin, PercentageIntMixin],

  props: {
    investments: {
      type: Number,
      default: 0
    }
  }
}
</script>
