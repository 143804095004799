<template>
  <div class="KpiStatsRow">
    <div class="columns is-variable-grid is-1">
      <div class="column is-2">
        <KpiStatHfValue
          :value="hfValue.current_total"
          :ptd="hfValue.ptd"
          :pct="hfValue.pct"
          :target="hfValue.target"
          :is-active="hfValue.is_active"
        />
      </div>
      <div class="column is-2">
        <KpiStatRoi
          :value="roi.current_total"
          :ptd="roi.ptd"
          :pct="roi.pct"
          :target="roi.target"
          :is-active="roi.is_active"
          :investments="roi.investments"
        />
      </div>
      <div class="column is-2">
        <KpiStatImpressions
          :value="impressions.current_total"
          :ptd="impressions.ptd"
          :pct="impressions.pct"
          :target="impressions.target"
          :is-active="impressions.is_active"
        />
      </div>
      <div class="column is-2">
        <KpiStatReachFactor
          :value="reachFactor.current_total"
          :ptd="reachFactor.ptd"
          :pct="reachFactor.pct"
          :target="reachFactor.target"
          :is-active="reachFactor.is_active"
        />
      </div>
      <div class="column is-2">
        <KpiStatClippings
          :value="clippings.current_total"
          :ptd="clippings.ptd"
          :pct="clippings.pct"
          :target="clippings.target"
          :is-active="clippings.is_active"
        />
      </div>
      <div class="column is-2">
        <KpiStatAPlusClippings
          :value="aplusClippings.current_total"
          :ptd="aplusClippings.ptd"
          :pct="aplusClippings.pct"
          :target="aplusClippings.target"
          :is-active="aplusClippings.is_active"
        />
      </div>
    </div>
  </div>
</template>

<script>
import KpiStatRoi from '@/components/kpis/stats/KpiStatRoi'
import KpiStatHfValue from '@/components/kpis/stats/KpiStatHfValue'
import KpiStatImpressions from '@/components/kpis/stats/KpiStatImpressions'
import KpiStatReachFactor from '@/components/kpis/stats/KpiStatReachFactor'
import KpiStatClippings from '@/components/kpis/stats/KpiStatClippings'
import KpiStatAPlusClippings from '@/components/kpis/stats/KpiStatAPlusClippings'

const kpiFactory = () => ({ current_total: 0, ptd: 0, ptc: 0, target: 0, is_active: false })
export default {
  components: {
    KpiStatImpressions,
    KpiStatReachFactor,
    KpiStatHfValue,
    KpiStatClippings,
    KpiStatAPlusClippings,
    KpiStatRoi
  },
  props: {
    hfValue: {
      type: Object,
      default: kpiFactory
    },
    roi: {
      type: Object,
      default: kpiFactory
    },
    impressions: {
      type: Object,
      default: kpiFactory
    },
    reachFactor: {
      type: Object,
      default: kpiFactory
    },
    clippings: {
      type: Object,
      default: kpiFactory
    },
    aplusClippings: {
      type: Object,
      default: kpiFactory
    }
  }
}
</script>
