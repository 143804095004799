<template>
  <div class="KpiCampaignMarketsMarket">
    <div v-if="country" class="KpiCampaignMarketsMarket__country p-b-s">
      <div class="is-flex is-aligned-middle">
        <div class="image is-24x24 is-round has-no-overflow">
          <div :style="{ backgroundImage: `url(${flagPath})` }" class="floated is-cover-image" />
        </div>
        <div class="m-l-m">
          {{ country.name }}
        </div>
      </div>
    </div>
    <KpiStatsRow
      :hf-value="hfValue"
      :roi="roi"
      :impressions="impressions"
      :reach-factor="reachFactor"
      :clippings="clippings"
      :aplus-clippings="aplusClippings"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import KpiStatsRow from '@/components/kpis/stats/KpiStatsRow'

export default {
  components: {
    KpiStatsRow
  },
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['getCountryByIso2']),
    country () {
      return this.market.country.data
    },
    flagPath () {
      let code = this.country.iso_alpha2
      return this.$asset(`/images/flags/${code.toLowerCase()}.svg`)
    },
    hfValue () {
      return this.market.kpis.pr_value
    },
    roi () {
      return this.market.kpis.roi
    },
    impressions () {
      return this.market.kpis.impressions
    },
    reachFactor () {
      return this.market.kpis.penetration_factor
    },
    clippings () {
      return this.market.kpis.amount_of_clippings
    },
    aplusClippings () {
      return this.market.kpis.aplus_clippings
    }
  }
}
</script>
<style lang="scss">
@import "~utils";

.KpiCampaignMarketsMarket {
  margin-bottom: $margin;
}
</style>
