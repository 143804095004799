<template>
  <div class="KpiCampaign__meta">
    <div class="is-flex is-aligned-middle">
      <round-avatar
        :src="campaignBrand.logo.cropped || campaignBrand.logo.original"
        :acronym-fallback="campaignBrand.name"
        size="small"
      />
      <div class="p-t-xs p-l-m">
        <div class="is-flex is-aligned-bottom">
          <restricted-button
            v-if="!campaign.is_completed"
            :to="{ name: 'performance.kpi.edit', params: { kpiId: campaign.id } }"
            permission="kpi.update"
            tag="a"
            data-testid="EditKpiCampaign"
          >
            <h3 class="KpiCampaign__name p-r-p">
              {{ campaign.name }}
            </h3>
          </restricted-button>
          <template v-else>
            <h3 class="KpiCampaign__name p-r-p">
              {{ campaign.name }}
            </h3>
          </template>
          <div class="KpiCampaign__marketsCount">
            {{ campaign.markets.data.length }} {{ $t('general.markets') }}
          </div>
        </div>
        <div v-if="campaign.start_date || campaign.end_date" class="is-size-sm">
          {{ publicationDate(campaign.start_date) }} - {{ publicationDate(campaign.end_date) }}
        </div>
      </div>
      <div class="m-l-a">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import PublicationDateMixin from '@hypefactors/shared/js/mixins/PublicationDateMixin'

export default {
  mixins: [PublicationDateMixin],
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    campaignBrand () {
      return this.campaign.brand.data
    }
  }
}
</script>
